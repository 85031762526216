import React from 'react';

class WaterWaveWrapper extends React.Component {
	state = {
		Component: null,
	};

	componentDidMount() {
		import('react-water-wave').then((componentImport) => {
			this.setState({ Component: componentImport.default });
		});
	}

	render() {
		const {
			img, dropRadius, perturbance, resolution, style,
		} = this.props;
		const { Component } = this.state;

		if (!Component) return null;

		return (
			<Component
				imageUrl={img}
				style={{ ...style }}
				dropRadius={dropRadius}
				perturbance={perturbance}
				resolution={resolution}
			/>
		);
	}
}

export default WaterWaveWrapper;
