import React, { Component } from 'react';
import cn from 'classnames';
import Media from 'react-media';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import Carousel from 'nuka-carousel';

import Slider from '../three-way-slider-mobile/slider.js';
import ExperienceItem from './experience-item/experience-item.component.js'

import './experiences.component.scss';

import { ReactComponent as LeftArrow } from '../../assets/icons/ico_circle_arrow_left.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/ico_circle_arrow_right.svg';

class DesktopSlider extends Component {
	state = {
		slideIndex: 0
	};

	fetchNextCards = () => {
		const { fetchNext } = this.props;

		fetchNext();
	};

	beforeSlide({ cardsOnViewPort, cards, fetchNext, nextPos }) {
		const { slideIndex } = this.state;

		if (
			cards &&
			slideIndex + cardsOnViewPort === cards.length - 1 &&
			fetchNext
		) {
			this.fetchNextCards();
		}

		this.setState({ slideIndex: nextPos });
	}

	render() {
		const { cards, cardsOnViewPort, fetchNext } = this.props;

		const { slideIndex } = this.state;

		return (
			<Carousel
				slideIndex={slideIndex}
				dragging={cards.length > cardsOnViewPort}
				swiping={cards.length > cardsOnViewPort}
				zoomScale={1.2}
				easing="easeQuadInOut"
				renderCenterLeftControls={({ previousSlide, currentSlide }) => {
					if (!cards || cards.length <= cardsOnViewPort) return null;

					return (
						<div
							className={cn('control previous', {
								disabled: currentSlide === 0
							})}
							onClick={previousSlide}
						>
							{LeftArrow && <LeftArrow />}
						</div>
					);
				}}
				renderCenterRightControls={({ nextSlide, currentSlide }) => {
					if (!cards || cards.length <= cardsOnViewPort) return null;
					return (
						<div
							className={cn('control next', {
								disabled: currentSlide + cardsOnViewPort === cards.length
							})}
							onClick={nextSlide}
						>
							{RightArrow && <RightArrow />}
						</div>
					);
				}}
				renderBottomCenterControls={() => null}
				slidesToShow={cardsOnViewPort}
				beforeSlide={(prevPos, nextPos) => {
					if (prevPos < nextPos && fetchNext) {
						this.beforeSlide({
							nextPos,
							cards,
							cardsOnViewPort,
							fetchNext: this.fetchNextCards
						});
					}
				}}
			>
				{cards && cards.map(card => <ExperienceItem key="title" {...card} />)}
			</Carousel>
		);
	}
}

// eslint-disable-next-line react/no-multi-comp
class MobileSlider extends Component {
	fetchNextCards = () => {
		const { fetchNext } = this.props;

		fetchNext();
	};

	render() {
		const { cards, fetchNext, withButton, height } = this.props;

		if (!cards) {
			return null;
		}

		const slides = [];
		cards.map((card, index) =>
			slides.push({
				id: index,
				children: (
					<ExperienceItem key="title" {...card} withButton={withButton} />
				)
			})
		);

		if (fetchNext) {
			return (
				<Slider
					fetchNextSlider={this.fetchNextCards}
					slides={slides}
					width="100vw"
					height={height || 500}
					unity={70}
					margin={15}
					swipeable
				/>
			);
		}

		return (
			<Slider
				slides={slides}
				width="100vw"
				height={height || 500}
				unity={70}
				margin={15}
				swipeable
			/>
		);
	}
}

const ExperiencesList = ({
	cards = [],
	cardsOnViewPort = 3,
	fetchNext = false,
	withButton,
	height = 500
}) => {
	setDefaultProps({
		bounce: false,
		visibleClassName: 'visible',
		percent: 10
	});

	if (cards.length === 0) {
		return null;
	}

	return (
		<OnVisible className="experience-list row">
			<Media query="(max-width: 68em)">
				{matches =>
					matches ? (
						<MobileSlider
							cards={cards}
							fetchNext={fetchNext}
							withButton={withButton}
							height={height}
						/>
					) : (
						<div className="container container-fluid">
							<DesktopSlider
								cards={cards}
								cardsOnViewPort={
									cards.length < 3 ? cards.length : cardsOnViewPort
								}
								fetchNext={fetchNext || false}
							/>
						</div>
					)
				}
			</Media>
		</OnVisible>
	);
};

export default ExperiencesList;
