import React, { Component } from 'react';
import { withRouter } from 'react-router';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { actions } from '../../shared/store.js';
import { withLocaleContext } from '../../locales/locale-context.js';

import HomePageService from './home.service.js';

import HeadingSection from './heading.component.js';
import HowItWorksSection from './how-it-works.component.js';
import ExperiencesSection from './experiences.section.js';
import SubscribeNewsletterSection from './subscribe-newsletter.component.js';
import WaterWaveWrapper from './wave-section.component.js';
import Layout from '../../components/layout/layout.component.js';

import norte2020 from '../../assets/images/footer/norte_2020.png';
import uniaoEuropeia from '../../assets/images/footer/uniao_europeia.png';
import portugal2020 from '../../assets/images/footer/portugal_2020.png';

import './home.scss';

const Download = injectIntl(({ intl }) => (
	<div className="found found-label">
		<Link target="_blank" to="/project-sheet">
			{intl.formatMessage({ id: 'footer.download-project-sheet' })}
		</Link>
		<Link target="_blank" to="/internationalization-project-sheet">
			{intl.formatMessage({ id: 'footer.internationalization-sheet' })}
		</Link>
	</div>
));

const HomeFooter = () => (
	<div className="homepage-footer">
		<div className="container container-fluid center-xs start-md">
			<div className="found">
				<img src={norte2020} alt="Norte 2020" />
			</div>
			<div className="found">
				<img src={portugal2020} alt="Portugal 2020" />
			</div>
			<div className="found">
				<img src={uniaoEuropeia} alt="UE" />
			</div>
			<Download />
		</div>
	</div>
);

class HomePage extends Component {
	static prefetchData = async language => Promise.all([
		HomePageService.getHomePageData(language),
		HomePageService.getRandomExperiences(language),
		HomePageService.getExperiences(language),
	]);

	state = {
		hasError: false,
		onTop: true,
	};

	componentDidMount() {
		const { homePageData, setLoading, homePageExperiences } = this.props;

		if (!homePageData || !homePageExperiences) {
			this.fetchData();
		} else {
			setLoading(false);
		}

		if (window) {
			window.addEventListener('scroll', this.listenScrollEvent);
		}
	}

	componentDidUpdate(prevProps) {
		const { localeContext } = this.props;

		if (prevProps.localeContext.locale.value !== localeContext.locale.value) {
			this.fetchData();
		}
	}

	fetchData() {
		const {
			setLoading,
			setLoaded,
			setHomePageData,
			setHomePageExperiences,
			setSearchExperiences,
			localeContext: { locale },
		} = this.props;

		setLoading(true);
		setLoaded(false);

		HomePage.prefetchData(locale.value)
			.then(([homepageData, experiences, barExperiences]) => {
				setHomePageData(homepageData);
				setHomePageExperiences(experiences);
				setSearchExperiences(barExperiences);
				setLoaded(true);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				setLoaded(false);

				this.setState({
					hasError: true,
				});
			});
	}

	listenScrollEvent = () => {
		if (window.scrollY > 200) {
			this.setState({ onTop: false });
		} else {
			this.setState({ onTop: true });
		}
	};

	render() {
		const { hasError, onTop } = this.state;
		const {
			homePageData, intl, isLoading, homePageExperiences, searchExperiences,
		} = this.props;

		if (isLoading) return null;

		if (hasError || !homePageData || !homePageExperiences) {
			return <h3>Not Implemented</h3>;
		}

		const {
			headerTitle,
			howItWillWorkTitle,
			latestNewsTitle,
			steps,
			singleExperienceTitle,
			singleExperienceDescription,
			projectPDF,
		} = homePageData;

		return (
			<Layout>
				<div id="home-page">
					<Helmet>
						<title>
							{intl.formatMessage({
								id: 'navigation.homepage',
							})}
						</title>
					</Helmet>

					<HeadingSection title={headerTitle} onTop={onTop} experiences={searchExperiences} />

					<ExperiencesSection experiences={homePageExperiences} />

					<HowItWorksSection title={howItWillWorkTitle} steps={steps} />

					<WaterWaveWrapper busTitle={singleExperienceTitle} busDescription={singleExperienceDescription} />

					<SubscribeNewsletterSection title={latestNewsTitle} />

				</div>
				<HomeFooter projectPDF={projectPDF} />
			</Layout>
		);
	}
}

const mapToProps = ({
	homePageData, contentData, isLoading, homePageExperiences, searchExperiences,
}) => ({
	homePageData,
	contentData,
	homePageExperiences,
	searchExperiences,
	isLoading,
});

export default withRouter(
	injectIntl(
		connect(
			mapToProps,
			actions,
		)(withLocaleContext(HomePage)),
	),
);

export { HomePage };
