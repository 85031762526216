import React from 'react';
import cn from 'classnames';

import './inputs.scss';

const TextBox = ({
	type,
	name,
	ariaLabel,
	placeholder,
	value,
	onChange,
	required,
	message,
	error,
	maxLength,
	validationMessage,
	handleChange,
	handleBlur,
	fullWidth,
}) => (
	<div
		className={cn('text-box', {
			error: error || false,
			'full-width': fullWidth,
		})}
	>
		<div className="text-box-input-wrapper">
			<input
				className="text-box-input"
				type={type}
				name={name}
				aria-label={ariaLabel}
				placeholder={placeholder}
				value={value}
				onChange={onChange || handleChange}
				onBlur={handleBlur}
				required={required}
				maxLength={maxLength}
			/>
		</div>
	</div>
);

export { TextBox };
