import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'redux-zero/react';
import { actions } from '../../shared/store.js';
import { withLocaleContext } from '../../locales/locale-context.js';
import mapExperienceToCheckout from '../../shared/mapExperience.js';

import Navbar from '../../layout/navbar.component.js';
import SingleDatePickerComponent
	from '../../components/single-date-picker/single-date-picker.component.js';
import CustomSelect from '../../components/custom-select/custom-select.component.js';

import './heading.component.scss';

const BookExperienceButton = ({ label }) => (
	<button type="submit" className="book-experience-button btn dark">
		{label}
	</button>
);

const mapExperience = experiences => (
	 experiences.map(exp => ({ label: exp.name, value: JSON.stringify(exp) }))
);

const BookingBar = ({
	intl, experiences, setCheckoutFilters, history,
}) => (
	<Formik
		initialValues={{
			experience: null,
			date: null,
		}}
		onSubmit={(values, { setSubmitting }) => {
			setCheckoutFilters({
				...mapExperienceToCheckout(JSON.parse(values.experience)),
				schedule: { time: null, day: values.date },
			});
			history.push('/checkout');
			setSubmitting(false);
		}}
	>
		{({ setFieldValue, handleSubmit }) => (
			<form onSubmit={handleSubmit}>
				<div className="row booking-bar middle-xs center-xs">

					<div className="col-xs-12 col-sm-4 col-md-3 middle-xs field">
						<SingleDatePickerComponent
							placeholder={intl.formatMessage({
								id: 'homepage.date',
							})}
							setFieldValue={setFieldValue}
							name="date"
							icon
							largeBar
						/>
					</div>

					<div className="col-xs-12 col-sm-7 col-md-5 middle-xs field">
						<CustomSelect
							placeholder={intl.formatMessage({
								id: 'homepage.experiences',
							})}
							fieldName="experience"
							setFieldValue={setFieldValue}
							options={mapExperience(experiences)}
						/>
					</div>

					<div className="col-xs-12 col-sm-12 col-md-4 middle-xs field">
						<BookExperienceButton
							label={<FormattedMessage id="button.book-experience" />}
						/>
					</div>
				</div>
			</form>
		)}
	</Formik>
);


const Heading = ({
	onTop, title, intl, experiences, setCheckoutFilters, history,
}) => (
	<section className="heading">
		<Navbar transparent={onTop} />
		<div className="image-wrapper">
			<div className="bg-img">
				<div className="dark-layer" />
				<div className="container container-fluid">
					<div className="header-content">
						<div className="image-wrapper-logos">
							<div className="image-wrapper-logo-powered" />
							<div className="image-wrapper-splash" />
						</div>
						<div className="image-wrapper-content center-xs">
							<div className="title">
								<h1>{title}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="container container-fluid">
			<BookingBar
				intl={intl}
				experiences={experiences}
				setCheckoutFilters={setCheckoutFilters}
				history={history}
			/>
		</div>
	</section>
);


const mapToProps = ({ checkoutFilters }) => ({ checkoutFilters });

export default withRouter(
	injectIntl(
		connect(
			mapToProps,
			actions,
		)(withLocaleContext(Heading)),
	),
);
