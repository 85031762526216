function IndexOfDefaultTicket(array) {
	const index = array.findIndex(price => price.defaultTicket);
	return index === -1 ? 0 : index;
}

function mapExperience(experience) {
	const prices = [];
	experience.prices.map(price => prices.push({
		...price,
		quantity: 0,
	}));
	const defaultTicketIndex = IndexOfDefaultTicket(experience.prices);
	prices[defaultTicketIndex].minAmount = 1;
	prices[defaultTicketIndex].quantity = 1;

	const extras = [];
	experience.extras.map(extra => extras.push({
		id: extra.id,
		name: extra.name,
		quantity: extra.minAmount || 0,
		notes: extra.notes || null,
		description: extra.description || null,
		price: extra.price.value,
		information: extra.information || null,
		image: extra.image || null,
		capacity: extra.capacity || null,
	}));

	return {
		...experience,
		prices,
		extras,
		selectedMethod: 'email',
		schedule: { time: null, day: null, session: null },
	};
}

export default mapExperience;
