import React, {Fragment} from 'react';
import Footer from "../../layout/footer";

const Layout = ({ children }) => {
	return (
		<Fragment>
			<div className="page-wrapper">
				{children}
			</div>
			<Footer />
		</Fragment>
		);
};

export default Layout;
