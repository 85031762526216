import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Media from 'react-media';
import cn from 'classnames';
import { Field, Formik } from 'formik';

import HomePageService from './home.service.js';

import { PrimaryButton } from '../../components/ui-toolkit/buttons.js';
import { FormTextBox } from '../../components/ui-toolkit/form-inputs.js';
import WaterWave from '../../components/water-wave/water-wave.component.js';

import check from '../../assets/icons/check.svg';
import phibi from '../../assets/images/home/subscribe/phibi.png';

import './subscribe-newsletter.component.scss';

class SubscribeNewsletter extends Component {
	state = {
		hasFormError: false,
		submitted: false,
	};

	render() {
		const {
			title,
			intl: { formatMessage },
		} = this.props;
		const { hasFormError, submitted } = this.state;

		return (
			<section className="latest-news">
				<div className="container container-fluid">
					<div className="latest-news-background ">
						<div className="phibi">
							<Media query={{ 'max-width': '62em' }}>
								{matches => (matches ? (
									<img src={phibi} alt="Phibi" />
								) : (
									<WaterWave
										img={phibi}
										style={{
											width: 347,
											height: 270,
											backgroundSize: 'contain',
										}}
										dropRadius={10}
										perturbance={0.01}
									/>
								))
								}
							</Media>
						</div>
						<div className="row">
							<div className="col-xs-12">
								<div className="latest-news-wrapper">
									<div
										className="latest-news-title"
										// eslint-disable-next-line react/no-danger
										dangerouslySetInnerHTML={{ __html: title }}
									/>
									<Formik
										enableReinitialize
										initialValues={{
											email: '',
										}}
										onSubmit={(values, { setSubmitting }) => {
											setSubmitting(true);
											this.setState({ hasFormError: false });
											HomePageService.submitQuestion(values)
												.then(() => {
													setSubmitting(false);
													this.setState({
														submitted: true,
														hasFormError: false,
													});
												})
												.catch(() => {
													setSubmitting(false);
													this.setState({
														hasFormError: true,
													});
												});
										}}
									>
										{({ handleSubmit }) => (
											<form onSubmit={handleSubmit}>
												<div
													className={cn('input-container', {
														submitted,
														hasFormError,
													})}
												>
													<Field
														component={FormTextBox}
														type="email"
														name="email"
														placeholder={formatMessage({
															id: 'subscribe-now',
														})}
														fullWidth
														required
													/>
													<PrimaryButton type="submit">
														<FormattedMessage id="homepage.form.send" />
													</PrimaryButton>
													<div className="success-message">

														<span className="check-icon">
															<img src={check} alt="Submitted" />
														</span>

														<div className="message">
															<FormattedMessage id="subscribe.thanks" />
														</div>

													</div>
													<div className="error-message">
														<FormattedMessage id="subscribe.errorForm" />
													</div>
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default injectIntl(SubscribeNewsletter);
