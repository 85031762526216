import React, { Component } from 'react';
import Swipe from 'react-easy-swipe';

import './slider.scss';
import cn from 'classnames';

class Slider extends Component {
	static defaultProps = {
		unity: 60,
		margin: 20,
		height: 500,
		wrapAround: true,
		controllers: false,
	};

	state = {
		position: 0,
		canAnimate: true,
		swipeable: false,
		isAnimating: false,
	};

	componentWillUnmount() {
		if (this.timerHandle) {
			clearTimeout(this.timerHandle);
			this.timerHandle = 0;
		}
	}

	onSwipeLeft(forceSwipe = false) {
		const { fetchNextSlider, wrapAround } = this.props;
		const { position, isAnimating, swipeable } = this.state;

		const { slides } = this.props;

		const max = slides.length - 1;

		if (isAnimating || (max <= 2 && position === max) || (!wrapAround && position === max)) {
			return;
		}

		if (swipeable || forceSwipe) {
			this.moveTo(position + 1);
			if (fetchNextSlider) {
				if (slides && position + 1 === slides.length - 1 && fetchNextSlider) {
					fetchNextSlider();
				}
			}
		}
	}

	onSwipeRight(forceSwipe = false) {
		const { slides, wrapAround } = this.props;
		const { position, isAnimating, swipeable } = this.state;

		const max = slides.length - 1;

		if (isAnimating || (max <= 2 && position === 0) || (!wrapAround && position === 0)) {
			return;
		}

		if (swipeable || forceSwipe) {
			this.moveTo(position - 1);
		}
	}

	onSwipeMove(position) {
		this.setState({
			swipeable: Math.abs(position.x) > 40,
		});

		return Math.abs(position.x) > 40;
	}

	handleOnClick(id, position, selected = false) {
		const { slides, handleOnClick } = this.props;
		const { isAnimating } = this.props;

		if (isAnimating) {
			return;
		}

		if (selected) {
			if (handleOnClick) {
				handleOnClick(id);
			}
			return;
		}

		let newPosition = position;

		const max = slides.length - 1;

		if (max > 2) {
			if (newPosition === 0 && this.state.position === slides.length - 1) {
				newPosition = slides.length;
			} else if (
				newPosition === slides.length - 1
				&& this.state.position === 0
			) {
				newPosition = -1;
			}
		}

		this.moveTo(newPosition);
	}

	moveTo(position) {
		const { slides } = this.props;

		this.setState(() => {
			if (position >= slides.length) {
				this.timerHandle = setTimeout(() => {
					this.setState({
						position: 0,
						canAnimate: false,
						isAnimating: false,
					});
					this.timerHandle = 0;
				}, 350);
			} else if (position < 0) {
				this.timerHandle = setTimeout(() => {
					this.setState({
						position: slides.length - 1,
						canAnimate: false,
						isAnimating: false,
					});
					this.timerHandle = 0;
				}, 350);
			} else {
				this.timerHandle = setTimeout(() => {
					this.setState({
						isAnimating: false,
					});
					this.timerHandle = 0;
				}, 350);
			}

			return {
				position,
				canAnimate: true,
				isAnimating: true,
			};
		});
	}

	calcPositionSlide(index) {
		const { unity, slides, wrapAround } = this.props;
		const { position } = this.state;

		const max = slides.length - 1;

		if (!wrapAround) {
			if (position <= 0) {
				if (index === max && max > 2) {
					return unity;
				}
				if (slides.length > 3 && index === max - 1) {
					return (unity * 2);
				}
			} else if (position >= max && max > 2) {
				if (index === 0) {
					return 0;
				}
				if (slides.length > 3 && index === 1) {
					return 0;
				}
			}

			return unity * index;
		}

		if (position <= 0) {
			if (index === max && max > 2) {
				return -unity;
			}
			if (slides.length > 3 && index === max - 1) {
				return -(unity * 2);
			}
		} else if (position >= max && max > 2) {
			if (index === 0) {
				return unity * slides.length;
			}
			if (slides.length > 3 && index === 1) {
				return unity * (slides.length + 1);
			}
		}

		return unity * index;
	}

	isSelected(index) {
		const { slides } = this.props;
		const { position } = this.state;

		const max = slides.length - 1;

		if (position < 0 && index === slides.length - 1) {
			return true;
		}
		if (position > max && index === 0) {
			return true;
		}

		return index === position;
	}

	render() {
		const { canAnimate, position } = this.state;
		const {
			unity, slides, margin, height, hasPagination, controllers,
		} = this.props;

		if (!slides) return null;


		const move = -(position * unity - margin);

		return (
			<div className="bright-mobile-slider">
				<Swipe
					className="slider"
					style={{ height }}
					allowMouseEvents
					onSwipeLeft={() => this.onSwipeLeft()}
					onSwipeRight={() => this.onSwipeRight()}
					onSwipeMove={pos => this.onSwipeMove(pos)}
				>
					<div
						className={`slides${canAnimate ? ' animated' : ''}`}
						style={{ transform: `translateX(${move}%)` }}
					>
						{slides.map((slide, index) => (
							<div
								key={slide.id}
								className={`slide${this.isSelected(index) ? ' selected' : ''}`}
								style={{
									left: `${this.calcPositionSlide(index)}%`,
									width: `${unity}%`,
								}}
								onClick={() => this.handleOnClick(slide.id, index, this.isSelected(index))
								}
							>
								{slide.children}
							</div>
						))}
					</div>
				</Swipe>
				{controllers
				&& (
					<div className="controllers-wrapper">
						<div className="controllers">
							<div
								className={cn('control previous', { hide: position === 0 })}
								onClick={() => this.onSwipeRight(true)}
							/>
							<div
								className={cn('control next', { hide: position === slides.length - 1 })}
								onClick={() => this.onSwipeLeft(true)}
							/>
						</div>
					</div>
				)
				}
				{hasPagination && slides.length > 1 && (
					<div className="container container-fluid">
						<div className="row">
							<div className="col-xs-12 center-xs">
								<div className="pagination">
									{slides.map((slide, index) => (
										<div
											key={slide.id}
											className={`circle${
												this.isSelected(index) ? ' selected' : ''
											}`}
											onClick={() => this.handleOnClick(
												slide.id,
												index,
												this.isSelected(index),
											)
											}
										/>
									))}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default Slider;
