import React, { Component } from 'react';
import Media from 'react-media';
import cn from 'classnames';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize.js';

import 'moment/locale/pt.js';
import 'moment/locale/en-ca.js';


import CalendarIcon from '../../assets/images/tour/ico_calendar.svg';

class SingleDatePickerComponent extends Component {
		state = {
			date: null,
			focused: null,
			clicked: false,
		};


	closeOnclick = () => {
		const { clicked } = this.state;
		if (!clicked) {
			this.setState({ clicked: true, focused: true });
			return;
		}
		this.setState({ clicked: false, focused: false });
	};

	handleDateChange = (date) => {
		this.setState({ date });

		const { setFieldValue, name } = this.props;
		if (setFieldValue) {
			setFieldValue(name, date);
		}
	};

	componentDidUpdate(prevProps) {
		const { value } = this.props;
		if (prevProps.value && prevProps.value !== value && !value) {
			this.setState({ date: null });
		}
	}

	render() {
		const {
			placeholder, icon, dark, intl, largeBar, tinyBar,
		} = this.props;
		const { date, focused, clicked } = this.state;

		moment.locale(intl.locale);

		return (
			<div className={cn('date-picker', { icon }, { dark }, { largeBar }, { tinyBar })} onClick={this.closeOnclick}>
				{icon && (
					<div className="icon-image">
						{CalendarIcon && <img src={CalendarIcon} alt="date-picker" />}
					</div>
				)}
				<Media query="(min-width: 767px)">
					{matches => (matches ? (
						<SingleDatePicker
							readOnly
							inputIconPosition="after"
							customInputIcon={(
								<svg
									height="20"
									width="20"
									viewBox="0 0 20 20"
									aria-hidden="true"
									focusable="false"
									className="css-19bqh2r"
								>
									<path
										fill="hsl(0,0%,80%)"
										d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
									/>
								</svg>
							)}
							navNext={(
								<span className="nav-next-page">
									<svg
										width="36px"
										height="36px"
										viewBox="0 0 53 53"
										version="1.1"
									>
										<g
											id="website_v2"
											stroke="none"
											strokeWidth="1"
											fill="none"
											fillRule="evenodd"
										>
											<g
												id="the_tour+experiences"
												transform="translate(-1338.000000, -1780.000000)"
												stroke="#142428"
											>
												<g
													id="ico_arrow_right"
													transform="translate(1339.000000, 1781.000000)"
												>
													<g id="Group-14">
														<g id="Group-21">
															<circle
																stroke="rgba(20,36,40,0.2)"
																id="Oval-Copy-2"
																cx="25.5"
																cy="25.5"
																r="25.5"
															/>
															<polyline
																stroke="rgba(20,36,40,0.2)"
																id="Path-10"
																strokeWidth="2"
																strokeLinecap="round"
																strokeLinejoin="round"
																points="23 19 30 26 23 33"
															/>
														</g>
													</g>
												</g>
											</g>
										</g>
									</svg>
								</span>
							)}
							navPrev={(
								<span className="nav-previous-page" style={{ width: '100%' }}>
									<svg
										width="36px"
										height="36px"
										viewBox="0 0 53 53"
										version="1.1"
									>
										<g
											id="website_v2"
											stroke="none"
											strokeWidth="1"
											fill="none"
											fillRule="evenodd"
										>
											<g
												id="the_tour+experiences"
												transform="translate(-49.000000, -1780.000000)"
												stroke="#142428"
											>
												<g
													id="ico_arrow_left"
													transform="translate(75.500000, 1806.500000) scale(-1, 1) translate(-75.500000, -1806.500000) translate(50.000000, 1781.000000)"
												>
													<circle
														stroke="rgba(20,36,40,0.2)"
														id="Oval-Copy-2"
														cx="25.5"
														cy="25.5"
														r="25.5"
													/>
													<polyline
														stroke="rgba(20,36,40,0.2)"
														id="Path-10"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
														points="23 19 30 26 23 33"
													/>
												</g>
											</g>
										</g>
									</svg>
								</span>
							)}
							numberOfMonths={1}
							date={date}
							onDateChange={date => this.handleDateChange(date)}
							focused={focused}
							onFocusChange={() => {
								if (!clicked) { return; }
								this.setState({ focused: false, clicked: false });
							}}
							hideKeyboardShortcutsPanel
							noBorder
							daySize={33}
							firstDayOfWeek={1}
							weekDayFormat="dd"
							displayFormat="DD/MM/YYYY"
							placeholder={placeholder || 'Date'}
						/>
					) : (
						<div className="input-picker">
							<input
								className="input-date-mobile"
								defaultValue={moment().format('YYYY-MM-DD')}
								min={moment().format('YYYY-MM-DD')}
								name="date"
								type="date"
								placeholder={placeholder || 'Date'}
							/>
						</div>
					))
					}
				</Media>
			</div>
		);
	}
}

export default injectIntl(SingleDatePickerComponent);
