import React from 'react';

import OnVisible from 'react-on-visible';
import Media from 'react-media';

import WaterWave from '../../components/water-wave/water-wave.component.js';

import river from '../../assets/images/home/img_river.png';

import './wave-section.component.scss';

const WaveSection = ({ busTitle, busDescription }) => (
	<section className="bus">
		<div className="container container-fluid">
			<div className="row content">
				<div className="col-xs-12 col-md-6 info">
					<OnVisible className="bus-content">
						<h2 className="title">{busTitle}</h2>
						<h6 className="subtitle">{busDescription}</h6>
					</OnVisible>
				</div>
				<div className="col-xs-12 col-md-6 image">
					<Media query={{ 'max-width': '62em' }}>
						{matches => (matches ? (
							<div className="frequently-questions-image" />
						) : (
							<WaterWave
								img={river}
								style={{
									width: 919,
									height: 594,
									backgroundSize: 'contain',
									position: 'absolute',
									left: 100,
									top: -45,
								}}
								dropRadius={10}
								perturbance={0.003}
								resolution={800}
							/>
						))
						}
					</Media>
				</div>
			</div>
		</div>
	</section>

);


export default WaveSection;
