import React, { Component } from 'react';
import Select, { components } from 'react-select';

import { ReactComponent as MapIcon } from '../../assets/images/tour/ico_map.svg';

import './custom-select.component.scss';

const ExperiencePlaceholder = props => components.Placeholder && (
	<components.Placeholder {...props}>
		<div className="custom-placeholder">
			<div className="placeholder-label">{props.children}</div>
		</div>
	</components.Placeholder>
);

const CustomSelect = ({
	children, options, dark, icon, value, ...restProps
}) => (
	<div className="custom-select">
		<div className="custom-select-icon">
			{icon || <MapIcon />}
		</div>
		<Select
			{...restProps}
			options={options}
			value={value}
			styles={{
				container: provided => ({
					...provided,
					border: 0,
				}),
				control: (provided, state) => ({
					...provided,
					minWidth: 150,
					border: state.isFocused ? '1px solid #46BED6' : '1px solid transparent',
					backgroundColor: (dark || state.isFocused) ? 'rgba(20,36,40,0.05)' : 'transparent',
					borderRadius: 0,
					boxShadow: 0,
					'&:hover': {
						borderColor: '#46BED6',
						backgroundColor: 'rgba(20,36,40,0.05)',
					},
				}),
				indicatorSeparator: () => ({
					width: 0,
				}),
				singleValue: provided => ({
					...provided,
					color: 'rgba(20, 36, 40, 0.7)',
					textAlign: 'left',
					fontWeight: 500,
				}),
				menu: provided => ({
					...provided,
					textAlign: 'left',
					width: '100%',
					color: '#132427',
					marginTop: '15px',
				}),
				option: (provided, state) => ({
					...provided,
					textAlign: 'left',
					width: '100%',
					color: state.isSelected ? '#46BED6' : '#132427',
					backgroundColor: state.isSelected || state.isFocused ? 'rgba(20, 36, 40, 0.05)' : 'transparent',
					padding: '16px 28px',
					borderBottom: '1px solid rgba(20, 36, 40, 0.05)',
					cursor: 'pointer',
				}),
			}}
		/>
	</div>
);

class CustomSelectComponent extends Component {
	state = {
		selectValue: null,
	};

	componentDidUpdate(prevProps) {
		const { value } = this.props;
		if (prevProps.value && prevProps.value !== value && !value) {
			this.setState({ selectValue: null });
		}
	}

	handleValue = (value) => {
		const { setFieldValue, fieldName } = this.props;
		this.setState({ selectValue: value });
		setFieldValue(fieldName, (value ? value.value : null));
	};

	render() {
		const {
			placeholder, options, icon, isClearable,
		} = this.props;
		const { selectValue } = this.state;

		return (
			<CustomSelect
				placeholder={placeholder || 'Combine Experience'}
				isClearable={isClearable}
				icon={icon}
				components={{ Placeholder: ExperiencePlaceholder }}
				onChange={option => this.handleValue(option)}
				options={options}
				value={selectValue}
			/>
		);
	}
}


export default CustomSelectComponent;
