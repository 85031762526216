import React, { Component } from 'react';
// eslint-disable-next-line import/extensions
import { connect } from 'redux-zero/react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import { actions } from '../shared/store.js';
import MainService from '../shared/main.service.js';
import LocaleSwitcher from './locale-switcher.js';
import { withLocaleContext } from '../locales/locale-context.js';

import './footer.scss';

// const ExternalLink = ({ link, label }) => (
// 	<a href={link} target="_blank" rel="noopener noreferrer">
// 		{label}
// 	</a>
// );

const FooterCopyright = () => (
	<FormattedMessage
		id="footer.copyright"
		values={{ year: new Date().getFullYear() }}
	/>
);

class Footer extends Component {
	static prefetchData = async language => MainService.getContactsData(language);

	componentDidMount() {
		const { contactsPageData, mainData } = this.props;

		if (!contactsPageData || !mainData) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		const { localeContext } = this.props;

		if (prevProps.localeContext.locale.value !== localeContext.locale.value) {
			this.fetchData();
		}
	}

	fetchData() {
		const { localeContext, setContentData } = this.props;
		Footer.prefetchData(localeContext.locale.value).then(data => {
			setContentData(data);
		});
	}

	render() {
		const { contentData, intl } = this.props;

		if (!contentData) {
			return null;
		}

		const { email, phoneNumber } = contentData;

		return (
			<footer className="site-footer">
				<div className="footer-top container container-fluid">
					<div className="row">
						<div className="col-xs-12 col-md-2">
							<div className="footer-column">
								<span className="footer-column-title">
									{intl.formatMessage({ id: 'footer.helpful-links' })}
								</span>
								<ul className="footer-column-list">
									<li>
										<Link to="/faqs">FAQs</Link>
									</li>
									<li>
										<Link to="/newsletters">Newsletters</Link>
									</li>
									<li>
										<Link to="/check-my-booking">
											{intl.formatMessage({ id: 'footer.check-my-books' })}
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xs-12 col-md-offset-1 col-md-2">
							<div className="footer-column">
								<span className="footer-column-title">
									{intl.formatMessage({ id: 'footer.contact-us' })}
								</span>
								<ul className="footer-column-list">
									{phoneNumber && (
										<li>
											<a
												href={`tel:${phoneNumber}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{phoneNumber}
											</a>
										</li>
									)}
									{email && (
										<li>
											{' '}
											<a
												href={`mailto:${email.replace(/\s/g, '')}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{email}
											</a>
										</li>
									)}
								</ul>
							</div>
						</div>
						<div className="col-xs-12 col-md-offset-4 col-md-3 end-md">
							<div className="social-accounts row">
								<div className="col-xs-12">
									<a
										href="//www.youtube.com"
										target="_blank"
										rel="noopener noreferrer"
										className="icon youtube"
									>
										<span>youtube</span>
									</a>
									<a
										href="//www.instagram.com"
										target="_blank"
										rel="noopener noreferrer"
										className="icon instagram"
									>
										<span>instagram</span>
									</a>
									<a
										href="//www.facebook.com"
										target="_blank"
										rel="noopener noreferrer"
										className="icon facebook"
									>
										<span>facebook</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom container container-fluid">
					<div className="row">
						<div className="col-xs-12 col-md-6">
							<FooterCopyright />
						</div>

						<div className="col-xs-12 col-md-6 end-md">
							<LocaleSwitcher />

							{/*<div className="links">*/}
							{/*	{contentData.privacyAndPolicyPDF && (*/}
							{/*		<ExternalLink*/}
							{/*			link={`${process.env.REACT_APP_API_ENDPOINT}/${*/}
							{/*				contentData.privacyAndPolicyPDF*/}
							{/*			}`}*/}
							{/*			label={intl.formatMessage({*/}
							{/*				id: 'footer.terms-and-conditions',*/}
							{/*			})}*/}
							{/*		/>*/}
							{/*	)}*/}

							{/*	{contentData.privacyAndPolicyPDF && (*/}
							{/*		<ExternalLink*/}
							{/*			link={`${process.env.REACT_APP_API_ENDPOINT}/${*/}
							{/*				contentData.termsAndConditionsPDF*/}
							{/*			}`}*/}
							{/*			label={intl.formatMessage({ id: 'footer.privacy' })}*/}
							{/*		/>*/}
							{/*	)}*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

const mapToProps = ({ contentData }) => ({
	contentData,
});

export default injectIntl(
	connect(
		mapToProps,
		actions,
	)(withLocaleContext(Footer)),
);

export { Footer };
