import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import ExperiencesList from '../../components/experiences/experiences.component.js';

import './experiences.section.scss';

const SeeAllExperiencesButton = ({ to, label }) => (
	<Link className="btn transparent-bordered see-all-experiences-button" to={to}>
		<span>{label}</span>
	</Link>
);

const ExperiencesTitle = ({ label, intl }) => (
	<div className="row">
		<div className="col-xs-12 col-sm-8">
			<h2 className="experiences-title">{ label || 'Combined experiences'}</h2>
		</div>
		<div className="col-sm-4 end-sm">
			<SeeAllExperiencesButton label={intl.formatMessage({ id: 'button.see-all' })} to="/experiences" />
		</div>
	</div>
);

const Experiences = ({ experiences, intl }) => {
	if (!experiences) return null;
	return (
		<section className="experiences">
			<div className="container container-fluid">
				<ExperiencesTitle intl={intl} label={intl.formatMessage({ id: 'homepage.experiences' })} />
			</div>
			<ExperiencesList cards={experiences} cardsOnViewPort={4} />

			<div className="container container-fluid">
				<Link to="/experiences">
					<div className="btn transparent-bordered see-all-button">
						{intl.formatMessage({ id: 'button.see-all' })}
					</div>
				</Link>

			</div>
		</section>
	);
};

export default injectIntl(Experiences);
