import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';

import './experience-item.component.scss';


function indexOfDefaultTicket(array) {
	if (!array) { return '--'; }
	const index = array.findIndex(price => price.defaultTicket);
	return array[index === -1 ? 0 : index].value;
}

const ExperienceItem = ({
	id, name, header, prices, coverImage, withButton,
}) => (
	<Link to={`/experiences/${id}`} className="experience-item-link">
		<div className="experience-item">
			<div className="experience-image-wrapper">
				<img
					src={coverImage ? coverImage.url : 'https://via.placeholder.com/274x274'}
					alt="experience"
				/>
				<div className="experience-price">
					<span>
						{<strong>{`€${indexOfDefaultTicket(prices) }`}</strong>}
						<FormattedMessage id="experiences.per-person" />
					</span>
				</div>
			</div>
			<h2 className="experience-title">{name}</h2>
			<p className="experience-description">
				{header}
			</p>
			{withButton && (
				<Link to={`/${name}`}>
					<div className="btn dark">
						<FormattedMessage id="button.book" />
					</div>
				</Link>
			)}
		</div>
	</Link>
);

export default ExperienceItem;
