import axios from 'axios';

const instanceAPI = axios.create({
	baseURL: process.env.REACT_APP_API_BACKEND,
});

function logError(error) {
	console.log(error);
	throw error;
}

class HomePageService {
	getHomePageData(language) {
		return axios
			.get(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/singletons/get/HomePage?lang=${language}`,
			)
			.then(res => res.data)
			.catch(logError);
	}

	submitQuestion(data) {
		const formData = new FormData();
		formData.set('form[email]', data.email);
		formData.set('form[question]', data.question);

		return axios
			.post(
				`${
					process.env.REACT_APP_API_ENDPOINT
				}/api/forms/submit/NotificationLatestNews?token=${process.env.REACT_APP_API_TOKEN}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			)
			.then(res => res)
			.catch(logError);
	}

	getExperiences(language) {
		return instanceAPI
			.get('/experience', { params: { lang: language, sort: 'name', sortDirection: 'asc' } })
			.then(({ data }) => data.data)
			.catch(e => logError(e));
	}

	getRandomExperiences(language) {
		return instanceAPI
			.get('/experience/random', { params: { limit: 4, lang: language } })
			.then(({ data }) => data)
			.catch(e => logError(e));
	}
}

export default new HomePageService();
