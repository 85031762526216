import React from 'react';
import OnVisible from 'react-on-visible';
import cn from 'classnames';

import './how-it-works.component.scss';

const Step = ({ step, index }) => (
	<div className="step">
		<div className="row">
			<div
				className={cn(
					'col-xs-12 col-md-4 icon-cell',
					{
						'last-md': index % 2,
					},
					{ 'col-md-offset-1': index % 2 === 0 },
				)}
			/>

			<div className="col-xs-2 col-md-2">
				<div className="number">
					<div className="number-wrapper">{index + 1}</div>
				</div>
			</div>
			<div
				className={`col-xs-10 col-md-4 content-cell ${cn({
					'col-md-offset-1 first-md': index % 2,
				})} `}
			>
				<OnVisible
					className="content animation1"
					visibleClassName="visible"
					percent={1}
				>
					<div className="step-icon">
						{step.icon && (
							<img
								src={`${process.env.REACT_APP_API_ENDPOINT + step.icon.path}`}
								alt={step.title}
							/>
						)}
					</div>
					<h4>{step.title}</h4>
					<p>{step.description}</p>
				</OnVisible>
			</div>
		</div>
	</div>
);

const HowItWorksSection = ({ title, steps = [] }) => (
	<section className="how-it-works">
		<div className="container container-fluid">
			<OnVisible visibleClassName="visible" percent={1}>
				<h2 className="title">{title}</h2>
			</OnVisible>
			<div className="steps">
				{steps.map((step, index) => (
					<Step key={step.value.title} step={step.value} index={index} />
				))}
			</div>
		</div>
	</section>
);

export default HowItWorksSection;
