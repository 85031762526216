import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './buttons.scss';

const PrimaryButton = ({
	big,
	disabled,
	children,
	type = 'button',
	extraClasses,
	onclick,
	link,
	external,
}) => {
	if (onclick) {
		return (
			// eslint-disable-next-line react/button-has-type
			<button
				disabled={disabled}
				onClick={onclick}
				type={type}
				className={cn('btn btn-primary', { 'btn-lg': big }, extraClasses)}
			>
				{children}
			</button>
		);
	}

	if (link && external) {
		return (
			<a
				className={cn('btn btn-primary', { 'btn-lg': big }, extraClasses)}
				href={link}
				target="_blank"
				rel="noopener noreferrer"
			>
				{children}
			</a>
		);
	}

	if (link) {
		return (
			<Link
				disabled={disabled}
				className={cn('btn btn-primary', { 'btn-lg': big }, extraClasses)}
				to={link}
			>
				{children}
			</Link>
		);
	}
	return (
		// eslint-disable-next-line react/button-has-type
		<button
			disabled={disabled}
			className={cn('btn btn-primary', { 'btn-lg': big }, extraClasses)}
			type={type}
		>
			{children}
		</button>
	);
};

const SecondaryButton = ({ disabled, children }) => (
	<button type="button" disabled={disabled} className="btn btn-outline-primary">
		{children}
	</button>
);

const Button = ({
	disabled, isLoading, children, type, extraClass, onClick,
}) => (
	<button
		type={type}
		onClick={onClick}
		className={cn('btn', extraClass, { loading: isLoading })}
		disabled={disabled}
	>
		{children}
	</button>
);

export { Button, PrimaryButton, SecondaryButton };
